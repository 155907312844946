import Paper, { PaperProps } from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import { FunctionComponent } from 'react';
import { isLight } from '../common/helpers';

const styles = {
    actionPaper: (theme: Theme): SxProps => ({
        width: '100%',
        height: 'inherit',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main
        ,
    }),
    paper: (theme: Theme, isAction?: boolean): SxProps => ({
        width: '100%',
        height: 'inherit',
        backgroundColor: !isAction
            ? isLight(theme)
                ? theme.palette.action.selected
                : theme.palette.secondary.main
            : isLight(theme)
                ? theme.palette.action.selected
                : theme.palette.info.main
        ,
    }),
};

export const ActionPaperPad: FunctionComponent<PaperProps> = props => <Paper
    { ...props }
    sx={ theme => ({
        ...styles.actionPaper(theme),
        ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
    }) }
>
    { props.children }
</Paper>;

const HomePaperPad: FunctionComponent<
    PaperProps & { isAction?: boolean }
> = props => {
    const { isAction, ...paperProps } = props;

    return <Paper
        { ...paperProps }
        sx={ theme => ({
            ...styles.paper(theme, isAction),
            ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
        }) }
    >
        { props.children }
    </Paper>;
};

export default HomePaperPad;
