import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SecureIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 15 19'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M6.5375 12.4093L11.525 7.42178L10.6062 6.5249L6.60313 10.528L4.39375 8.31865L3.43125 9.28115L6.5375 12.4093ZM7.5 18.228C5.45833 17.7176 3.78125 16.5327 2.46875 14.6733C1.15625 12.814 0.5 10.7759 0.5 8.55928V3.35303L7.5 0.728027L14.5 3.35303V8.55928C14.5 10.7759 13.8437 12.814 12.5312 14.6733C11.2187 16.5327 9.54167 17.7176 7.5 18.228ZM7.5 16.8718C9.17708 16.3176 10.5443 15.2713 11.6016 13.7327C12.6589 12.1942 13.1875 10.4697 13.1875 8.55928V4.27178L7.5 2.12803L1.8125 4.27178V8.55928C1.8125 10.4697 2.34115 12.1942 3.39844 13.7327C4.45573 15.2713 5.82292 16.3176 7.5 16.8718Z'
                }
                fill={ props.fill || theme.palette.text.primary}
            />
        </SvgIcon>
    );
}

export default SecureIcon;
