import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import LoyaltyStatusIcon from '../../assets/SvgIcons/LoyaltyStatusIcon';
import LoyaltyStatusBadgeIcon from
    '../../assets/SvgIcons/LoyaltyStatusBadgeIcon';
import PrizeIcon from '../../assets/SvgIcons/PrizeIcon';
import { LoyaltyStatusType } from '../../config/membersArea';
import { LoyaltyColor } from '../../common/themes';
import { KnownBreakpoints } from '../../common/constants';

export type LoyaltyStatusProps = SxProps & {
    status: LoyaltyStatusType;
};

const styles = {
    container: (theme: Theme) => ({
        position: 'relative',
        width: '118px',
        height: '118px',
        margin: '32px 0 25px 0',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '78px',
            height: '78px',
            margin: '18px 0 13px 0',
        },
    }),
    iconPad: (theme: Theme) => ({
        position: 'absolute',
        width: '118px',
        height: '118px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '78px',
            height: '78px',
        },
    }),
    prize: (theme: Theme) => ({
        position: 'absolute',
        width: '49.49px',
        height: '40.07px',
        top: '39.05px',
        left: '34.51px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '40px',
            height: '35px',
            top: '23px',
            left: '19px',
        },
    }),
    badge: (theme: Theme) => ({
        position: 'absolute',
        width: '30.93px',
        height: '28.62px',
        top: '-1px',
        right: '6.07px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '25px',
            height: '25px',
            top: '-5px',
            right: '4px',
        },
    }),
};

const LoyaltyIcon: FunctionComponent<LoyaltyStatusProps> = props => {
    const theme = useTheme<Theme>();
    const colorName = props.status.toLowerCase() as keyof LoyaltyColor;
    const bgColor = theme.palette.loyalty[colorName];

    return <Box sx={ styles.container }>
        <LoyaltyStatusIcon fill={ bgColor } sx={ styles.iconPad } />
        <PrizeIcon sx={ styles.prize } />
        <LoyaltyStatusBadgeIcon sx={ styles.badge } />
    </Box>;
};

export default LoyaltyIcon;
