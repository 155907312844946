import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function PrizeIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        viewBox="0 0 56 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        { ...props }
    >
        <path
            d={
                'M53.583 5.52371H45.3893V2.38407C45.3893 1.04683 44.3285 '
                + '0 42.9724 0H12.9683C11.6126 0 10.5513 1.04641 10.5513 '
                + '2.38407V5.52371H2.41694C1.06126 5.52371 0 6.57012 0 '
                + '7.90778V16.5132C0 22.0954 4.2442 26.7467 9.90291 '
                + '27.3282L16.7409 27.9679C17.3893 28.6655 18.0966 '
                + '29.4215 18.863 30.0611L23.4609 '
                + '34.0148V37.794H21.8692C20.6311 37.794 19.6291 38.7825 '
                + '19.6291 40.0037V41.1665L17.979 41.1669C16.8001 41.1669 '
                + '15.8569 42.0973 15.8569 43.2601V46.9068C15.8569 48.0696 '
                + '16.8001 49 17.979 49H38.021C39.1999 49 40.1431 48.0696 '
                + '40.1431 46.9068V43.3181C40.1431 42.1553 39.1999 41.2249 '
                + '38.021 41.2249H36.3705V40.0621C36.3705 38.8408 35.3685 '
                + '37.8525 34.1304 37.8525H32.5387V34.0732L37.1366 '
                + '30.1196C37.903 29.4799 38.5514 28.7823 39.2587 '
                + '28.0263L46.0967 27.3867C51.7558 26.8635 55.9996 '
                + '22.2118 55.9996 16.5716L56 7.90787C56 6.62867 54.9391 '
                + '5.52371 53.583 5.52371ZM10.3747 22.6185C7.19176 22.3277 '
                + '4.77482 19.7112 4.77482 '
                + '16.5131V10.2917H10.5515V12.1526C10.5515 15.9318 '
                + '11.4948 19.5952 13.2041 22.9095L10.3747 '
                + '22.6185ZM29.8273 25.8746V27.7355C29.8273 28.1427 '
                + '29.4736 28.4915 29.0608 28.4915H26.9387C26.5259 '
                + '28.4915 26.1723 28.1427 26.1723 27.7355L26.1727 '
                + '25.9331C23.9918 25.6423 22.2821 23.9562 22.0465 '
                + '21.7466C22.0465 21.5722 22.1053 21.3394 22.2233 '
                + '21.2234C22.3413 21.049 22.5769 20.991 22.813 '
                + '20.991H24.935C25.2887 20.991 25.5835 21.2234 '
                + '25.6423 21.5726C25.7603 22.0958 26.2319 22.4446 '
                + '26.7624 22.4446H28.6489C29.651 22.4446 30.4762 '
                + '21.747 30.5354 20.8746C30.5942 20.3514 30.4174 19.8862 '
                + '30.0638 19.479C29.7102 19.1302 29.2386 18.8974 28.7081 '
                + '18.8974H27.4104C24.5219 18.8974 22.0461 16.8041 21.8106 '
                + '14.1292C21.5157 11.3384 23.4611 8.89635 26.1728 '
                + '8.37274V6.51187C26.1728 6.10466 26.5264 5.75586 26.9392 '
                + '5.75586H29.0613C29.4741 5.75586 29.8277 6.10467 29.8277 '
                + '6.51187V8.31428C32.0087 8.60509 33.7184 10.2911 33.9539 '
                + '12.5007C33.9539 12.6752 33.8951 12.908 33.7771 '
                + '13.024C33.6591 13.1984 33.4235 13.2564 33.2467 '
                + '13.2564H31.1246C30.771 13.2564 30.4762 13.024 30.4174 '
                + '12.6747C30.2993 12.1515 29.8277 11.8027 29.2973 '
                + '11.8027H27.4107C26.4087 11.8027 25.5835 12.5004 25.5242 '
                + '13.3728C25.4654 13.896 25.6422 14.3612 25.9958 '
                + '14.7684C26.3495 15.1172 26.8211 15.35 27.3515 '
                + '15.35H28.9432C30.4757 15.35 31.8906 15.9896 32.9514 '
                + '17.0944C34.0122 18.1993 34.4839 19.6529 34.3071 '
                + '21.223C33.9535 23.4911 32.1855 25.3514 29.8273 '
                + '25.8746ZM51.1661 16.5132C51.1661 19.6528 48.7492 '
                + '22.2697 45.5663 22.6186L42.7369 22.9094C44.4462 19.5949 '
                + '45.3894 15.932 45.3894 12.1525V10.2916H51.1661V16.5132Z'
            }
            fill={ props.fill || theme.palette.common.white }
        />
    </SvgIcon>;
}

export default PrizeIcon;
