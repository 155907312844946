import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LoyaltyStatusIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 124 124'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <g filter='url(#filter0_d_211_9712)'>
                <circle
                    cx='62'
                    cy='59'
                    r='59'
                    fill={props.fill || theme.palette.loyalty.bronze}
                />
            </g>
            <defs>
                <filter
                    id='filter0_d_211_9712'
                    x='0'
                    y='0'
                    width='124'
                    height='124'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='3' />
                    <feGaussianBlur stdDeviation='1.5' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0.156771 0 0 0 0 0.237396 0 0 0 0
                        0.358333 0 0 0 0.2 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_211_9712'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_211_9712'
                        result='shape'
                    />
                </filter>
            </defs>
        </SvgIcon>
    );
}

export default LoyaltyStatusIcon;
