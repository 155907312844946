import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function DocumentIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 14 18' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M3.64648 13.7915H10.3548V12.5415H3.64648V13.7915ZM3.64648 10.2498H10.3548V8.99984H3.64648V10.2498ZM1.58398 17.3332C1.25065 17.3332 0.958984 17.2082 0.708984 16.9582C0.458984 16.7082 0.333984 16.4165 0.333984 16.0832V1.9165C0.333984 1.58317 0.458984 1.2915 0.708984 1.0415C0.958984 0.791504 1.25065 0.666504 1.58398 0.666504H9.10482L13.6673 5.229V16.0832C13.6673 16.4165 13.5423 16.7082 13.2923 16.9582C13.0423 17.2082 12.7507 17.3332 12.4173 17.3332H1.58398ZM8.47982 5.7915V1.9165H1.58398V16.0832H12.4173V5.7915H8.47982ZM1.58398 1.9165V5.7915V1.9165V16.0832V1.9165Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default DocumentIcon;
