import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function PaymentIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox={ '0 0 58 41' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            {...props}
        >
            <path
                d={
                    'M32.8756 24.5244C35.0284 24.5244 36.8583 23.8001 38.3652 22.3515C39.8722 20.9029 40.6256 19.1439 40.6256 17.0745C40.6256 15.0051 39.8722 13.2461 38.3652 11.7975C36.8583 10.3489 35.0284 9.62462 32.8756 9.62462C30.7229 9.62462 28.893 10.3489 27.3861 11.7975C25.8791 13.2461 25.1257 15.0051 25.1257 17.0745C25.1257 19.1439 25.8791 20.9029 27.3861 22.3515C28.893 23.8001 30.7229 24.5244 32.8756 24.5244ZM12.209 33.2159C11.1326 33.2159 10.2177 32.8537 9.46419 32.1294C8.71072 31.4051 8.33398 30.5256 8.33398 29.4909V4.65804C8.33398 3.62334 8.71072 2.74384 9.46419 2.01954C10.2177 1.29525 11.1326 0.933105 12.209 0.933105H53.5423C54.6187 0.933105 55.5336 1.29525 56.2871 2.01954C57.0406 2.74384 57.4173 3.62334 57.4173 4.65804V29.4909C57.4173 30.5256 57.0406 31.4051 56.2871 32.1294C55.5336 32.8537 54.6187 33.2159 53.5423 33.2159H12.209ZM4.45898 40.6657C3.3826 40.6657 2.46766 40.3036 1.71419 39.5793C0.960721 38.855 0.583984 37.9755 0.583984 36.9408V10.2454C0.583984 9.7074 0.76697 9.26248 1.13294 8.91068C1.49891 8.55888 1.96176 8.38298 2.52148 8.38298C3.08121 8.38298 3.54405 8.55888 3.91003 8.91068C4.276 9.26248 4.45898 9.7074 4.45898 10.2454V36.9408H47.7298C48.2895 36.9408 48.7524 37.1167 49.1184 37.4685C49.4843 37.8203 49.6673 38.2652 49.6673 38.8033C49.6673 39.3413 49.4843 39.7863 49.1184 40.1381C48.7524 40.4898 48.2895 40.6657 47.7298 40.6657H4.45898ZM12.209 10.8663C14.0173 10.8663 15.5458 10.2661 16.7944 9.06588C18.043 7.86562 18.6673 6.39634 18.6673 4.65804H12.209V10.8663ZM53.5423 10.8663V4.65804H47.084C47.084 6.39634 47.7083 7.86562 48.9569 9.06588C50.2055 10.2661 51.734 10.8663 53.5423 10.8663ZM12.209 29.4909H18.6673C18.6673 27.7526 18.043 26.2834 16.7944 25.0831C15.5458 23.8828 14.0173 23.2827 12.209 23.2827V29.4909ZM47.084 29.4909H53.5423V23.2827C51.734 23.2827 50.2055 23.8828 48.9569 25.0831C47.7083 26.2834 47.084 27.7526 47.084 29.4909Z'
                }
                fill={ props.fill || 'white' }
            />
        </SvgIcon>
    );
}

export default PaymentIcon;
