import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function InfoIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={ '0 0 18 18' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M8.43685 13.1665H9.68685V8.1665H8.43685V13.1665ZM8.99935 6.62484C9.19379 6.62484 9.35699 6.56234 9.48893 6.43734C9.62088 6.31234 9.68685 6.15262 9.68685 5.95817C9.68685 5.76373 9.62088 5.59706 9.48893 5.45817C9.35699 5.31928 9.19379 5.24984 8.99935 5.24984C8.8049 5.24984 8.64171 5.31928 8.50977 5.45817C8.37782 5.59706 8.31185 5.76373 8.31185 5.95817C8.31185 6.15262 8.37782 6.31234 8.50977 6.43734C8.64171 6.56234 8.8049 6.62484 8.99935 6.62484ZM8.99935 17.3332C7.86046 17.3332 6.78407 17.1144 5.77018 16.6769C4.75629 16.2394 3.87088 15.6422 3.11393 14.8853C2.35699 14.1283 1.75977 13.2429 1.32227 12.229C0.884766 11.2151 0.666016 10.1318 0.666016 8.979C0.666016 7.84012 0.884766 6.76373 1.32227 5.74984C1.75977 4.73595 2.35699 3.854 3.11393 3.104C3.87088 2.354 4.75629 1.76025 5.77018 1.32275C6.78407 0.885254 7.8674 0.666504 9.02018 0.666504C10.1591 0.666504 11.2355 0.885254 12.2493 1.32275C13.2632 1.76025 14.1452 2.354 14.8952 3.104C15.6452 3.854 16.2389 4.73595 16.6764 5.74984C17.1139 6.76373 17.3327 7.84706 17.3327 8.99984C17.3327 10.1387 17.1139 11.2151 16.6764 12.229C16.2389 13.2429 15.6452 14.1283 14.8952 14.8853C14.1452 15.6422 13.2632 16.2394 12.2493 16.6769C11.2355 17.1144 10.1521 17.3332 8.99935 17.3332ZM9.02018 16.0832C10.9785 16.0832 12.6452 15.3922 14.0202 14.0103C15.3952 12.6283 16.0827 10.9512 16.0827 8.979C16.0827 7.02067 15.3952 5.354 14.0202 3.979C12.6452 2.604 10.9716 1.9165 8.99935 1.9165C7.04102 1.9165 5.37088 2.604 3.98893 3.979C2.60699 5.354 1.91602 7.02762 1.91602 8.99984C1.91602 10.9582 2.60699 12.6283 3.98893 14.0103C5.37088 15.3922 7.04796 16.0832 9.02018 16.0832Z'
                }
                fill={ props.fill || theme.palette.text.primary }
            />
        </SvgIcon>
    );
}

export default InfoIcon;
