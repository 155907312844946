import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { isDark } from '../../common/helpers';

function SuccessIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();
    const defaultColor = isDark(theme)
        ? theme.palette.primary.dark
        : theme.palette.primary.main;

    return (
        <SvgIcon
            viewBox={ '0 0 83 83' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            { ...props }
        >
            <path
                d={
                    'M41.5 82.75C35.6562 82.75 30.225 81.7016 25.2063 79.6047C20.1875 77.5078 15.8219 74.6031 12.1094 70.8906C8.39688 67.1781 5.49219 62.8125 3.39531 57.7938C1.29844 52.775 0.25 47.3438 0.25 41.5C0.25 35.725 1.29844 30.3281 3.39531 25.3094C5.49219 20.2906 8.39688 15.925 12.1094 12.2125C15.8219 8.5 20.1875 5.57813 25.2063 3.44688C30.225 1.31563 35.6562 0.25 41.5 0.25C46.6562 0.25 51.4688 1.075 55.9375 2.725C60.4062 4.375 64.4281 6.64375 68.0031 9.53125L63.5688 13.9656C60.5438 11.5594 57.175 9.70312 53.4625 8.39687C49.75 7.09062 45.7625 6.4375 41.5 6.4375C31.5312 6.4375 23.1953 9.78906 16.4922 16.4922C9.78906 23.1953 6.4375 31.5312 6.4375 41.5C6.4375 51.4688 9.78906 59.8047 16.4922 66.5078C23.1953 73.2109 31.5312 76.5625 41.5 76.5625C51.4688 76.5625 59.8047 73.2109 66.5078 66.5078C73.2109 59.8047 76.5625 51.4688 76.5625 41.5C76.5625 39.4375 76.4078 37.4266 76.0984 35.4672C75.7891 33.5078 75.325 31.6 74.7062 29.7437L79.45 25C80.55 27.5438 81.375 30.1906 81.925 32.9406C82.475 35.6906 82.75 38.5438 82.75 41.5C82.75 47.3438 81.6844 52.775 79.5531 57.7938C77.4219 62.8125 74.5 67.1781 70.7875 70.8906C67.075 74.6031 62.7094 77.5078 57.6906 79.6047C52.6719 81.7016 47.275 82.75 41.5 82.75ZM35.4156 60.2687L18.4 43.15L23.0406 38.5094L35.4156 50.8844L78.1094 8.19062L82.8531 12.8313L35.4156 60.2687Z'
                }
                fill={ props.fill || defaultColor }
            />
        </SvgIcon>
    );
}

export default SuccessIcon;
