import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function WarningIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox={ '0 0 18 16' }
            fill={ 'none' }
            xmlns={ 'http://www.w3.org/2000/svg' }
            {...props}
        >
            <path
                d={
                    'M0.916667 15.5003C0.680556 15.5003 0.5 15.3962 0.375 15.1878C0.25 14.9795 0.25 14.7712 0.375 14.5628L8.45833 0.604492C8.58333 0.396159 8.76389 0.291992 9 0.291992C9.23611 0.291992 9.41667 0.396159 9.54167 0.604492L17.625 14.5628C17.75 14.7712 17.75 14.9795 17.625 15.1878C17.5 15.3962 17.3194 15.5003 17.0833 15.5003H0.916667ZM9.08333 6.08366C8.90278 6.08366 8.75347 6.14269 8.63542 6.26074C8.51736 6.3788 8.45833 6.5281 8.45833 6.70866V10.1253C8.45833 10.3059 8.51736 10.4552 8.63542 10.5732C8.75347 10.6913 8.90278 10.7503 9.08333 10.7503C9.26389 10.7503 9.41319 10.6913 9.53125 10.5732C9.6493 10.4552 9.70833 10.3059 9.70833 10.1253V6.70866C9.70833 6.5281 9.6493 6.3788 9.53125 6.26074C9.41319 6.14269 9.26389 6.08366 9.08333 6.08366ZM9.08333 13.0628C9.26389 13.0628 9.41319 13.0038 9.53125 12.8857C9.6493 12.7677 9.70833 12.6184 9.70833 12.4378C9.70833 12.2573 9.6493 12.108 9.53125 11.9899C9.41319 11.8719 9.26389 11.8128 9.08333 11.8128C8.90278 11.8128 8.75347 11.8719 8.63542 11.9899C8.51736 12.108 8.45833 12.2573 8.45833 12.4378C8.45833 12.6184 8.51736 12.7677 8.63542 12.8857C8.75347 13.0038 8.90278 13.0628 9.08333 13.0628Z'
                }
                fill={ props.fill }
            />
        </SvgIcon>
    );
}

export default WarningIcon;
