import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LoyaltyStatusBadgeIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        viewBox="0 0 31 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        { ...props }
    >
        <path
            d={
                'M15.4643 0L17.6513 12.2869L30.9286 14.3107L17.6513 '
                + '16.3346L15.4643 28.6215L13.2773 16.3346L0 14.3107L13.2773 '
                + '12.2869L15.4643 0Z'
            }
            fill={ props.fill || theme.palette.common.white }
        />
    </SvgIcon>;
}

export default LoyaltyStatusBadgeIcon;
