/**
 * Transform table for mobile.
 * Add `data-label` attribute to `td` elements.
 * This attribute will be used in CSS
 * (see global.css 'Responsive tables on mobile devices' section).
 *
 * @param table HTMLTableElement | null | undefined
 * @returns void
 */
const transformTable = (table: HTMLTableElement | null | undefined): void => {
    if(!table) return;

    const headers = table.querySelectorAll('th');
    const rows = table.querySelectorAll('tbody > tr');
    if (!rows) return;

    rows.forEach(row => {
        const cells = row.querySelectorAll('td');
        if (!cells) return;

        cells.forEach((cell, index) => cell.setAttribute(
            'data-label',
            headers[index].textContent || '',
        ));
    });
};

export default transformTable;
